import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import Zip from '../components/apply/zip'

const IndexPage = ({ location }) => {
  React.useEffect(() => {
    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'landing',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Layout phone="866-740-2901" location={location} showCountDown TTYBig addLegalCopy>
      <SEO
        title="Medicare Supplement Plans - Medicare Companion"
        keywords={[`medicare`, `insurance`, `plans`, `affordable`, `supplement`]}
      />
      <Header phone="866-740-2901" TTYBig />
      <Intro showTitle={true}>
        <div className="form-box">
          <div className="contacts-form alt text-center">
            <Zip shouldNavigate={true} homeOrigin="home" />
          </div>
        </div>
      </Intro>
      <main id="main">
        <Partners />
        <HomeContent hidePlans hideBenefitsSection />
      </main>
    </Layout>
  )
}

export default IndexPage
